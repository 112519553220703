a[class^="cta-btn"] {
  @media (max-width:767px) {
    display:block;
    margin-bottom:5px;
  }
}

.cta-btn {
  font-size: em(18);
  line-height: (36.4 / 18);
  letter-spacing: em(2, 18);
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  background-color: $pink-dark;
  box-shadow: 0 em(2, 18) 0 0 rgba(188, 95, 80, 1);
  padding: em(12, 18) em(43, 18);
  margin: 0 em(10);
  transition: 0.25s background-color ease-in-out;
  &:hover, &:focus {
    text-decoration: none;
    color: white;
    background-color: darken($pink-dark, 5%);
  }
}

.cta-btn-on-white {
  font-size: em(18);
  line-height: (36.4 / 18);
  letter-spacing: em(2, 18);
  font-weight: 500;
  text-transform: uppercase;
  color: $pink-dark;
  background-color: white;
  box-shadow: 0 em(1, 18) 0 0 rgba(188, 95, 80, 1);
  padding: em(18, 18) em(43, 18);
  margin: 0 em(10);
  transition: 0.25s all ease-in-out;
  &:hover, &:focus {
    text-decoration: none;
    color: white;
    background-color: $pink-dark;
  }
}

.cta-btn-transparent {
  font-size: em(14);
  line-height: (16 / 14);
  text-transform: uppercase;
  color: $pink-dark;
  background-color: transparent;
  font-weight: 500;
  text-align: center;
  padding: em(15, 14) em(34, 14);
  margin: 0 em(10);
  transition: 0.25s all ease-in-out;
  border: 2px solid $pink-dark;
  &.expanded {
    letter-spacing: em(3, 14);
  }
  &.gold {
    color: $gold;
    border-color: $gold;
    &:hover, &:focus {
      background-color: $gold;
    }
  }
  &:hover, &:focus {
    text-decoration: none;
    color: white;
    background-color: $pink-dark;
  }
}