.cta-box {
  width: auto;
  border: 2px solid #bbb2a6;
  height: 0;
  text-align: center;
  position: relative;
  @media (max-width: 500px) {
    padding-bottom: 75.7%;
  }
  @media (min-width: 501px) and (max-width: 991px) {
    padding-bottom: 46%;
  }
  @media (max-width: 991px) {
    margin: 5px;
  }
  @media (min-width: 992px) {
    padding-bottom: 75.7%;
    margin: 10px;
  }
  .inner {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    padding: em(30);
    max-width: 283px;
    margin: auto;
    h2 {
      margin-top: 0;
    }
    p {
      font-size: em(18);
      font-weight: 300;
      margin-bottom: em(25, 18);
    }
  }
}