//------------------------------------
    //SIDEBAR
//------------------------------------

[class^="sidebar-"],
[class*=" sidebar-"] {
	background:#fff;
	padding-top:1em;
	
	h2, h3, h4, h5 {
		&:first-child {
			margin-top:0;
		}
	}
	
	h3 {text-transform:uppercase;}
	
	a[href^="mailto"] {
		word-break:break-word;
	}
	
	.hs-menu-wrapper,
	.widget-module {
		
		a {
			padding:10px 12px !important;
			font-size:1em; 
			display:block;			
			border-bottom:1px solid $gray;
			@include transition(.2s);
		}
		
		.hs-menu-children-wrapper {display:none !important;}
		
		.active > a,
		.active .active a {
			color:$brand-primary;
		}
				
		.active-branch {
			&, .hs-menu-item {display:block !important;}
			
			&.active > a {}
			
			.hs-menu-children-wrapper {
				visibility:visible !important; 
				opacity:1 !important; 
				display:block !important; 
				position:static !important;				

				& > li {
					a {
						border-bottom:1px solid $gray-light;
					}
				}
				
				.active-branch {
					& > a {color:$brand-primary;}
				}
				
				.hs-menu-children-wrapper {
					display: none !important;
					font-size: .875em; 
					border-bottom: 1px solid $gray-lighter;
					background-color: $gray-lighter; 
				}
			}
		}
	}
	
}