/* Images */

img {
    max-width: 100%;
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; /* Suppress the space beneath the baseline */
}

/* Videos */

video {
    max-width: 100%;
    height: auto;
}

/* Embed Container (iFrame, Object, Embed) */

.hs-responsive-embed {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.hs-responsive-embed iframe, .hs-responsive-embed object, .hs-responsive-embed embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.hs-responsive-embed,
.hs-responsive-embed.hs-responsive-embed-youtube,
.hs-responsive-embed.hs-responsive-embed-wistia,
.hs-responsive-embed.hs-responsive-embed-vimeo {
    padding-bottom: 56.25%;
}

.hs-responsive-embed.hs-responsive-embed-instagram {
    padding-bottom: 116.01%;
}

.hs-responsive-embed.hs-responsive-embed-pinterest {
    height: auto;
    overflow: visible;
    padding: 0;
}

.hs-responsive-embed.hs-responsive-embed-pinterest iframe {
    position: static;
    width: auto;
    height: auto;
}

iframe[src^="http://www.slideshare.net/slideshow/embed_code/"] {
    width: 100%;
    max-width: 100%;
}

@media (max-width: 568px) {
    iframe {
        max-width: 100%;
    }
}

/* Forms */

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"]
select {
    padding: 6px;
    display: inline-block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* Menus */

.hs-menu-wrapper ul {
    padding: 0;
}

.hs-menu-wrapper.hs-menu-flow-horizontal ul {
    list-style: none;
    margin: 0;
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul {
    display: inline-block;
    margin-bottom: 20px;
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul:before {
    content: " ";
    display: table;
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul:after {
    content: " ";
    display: table;
    clear: both;
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-menu-depth-1 {
    float: left;
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul li a {
    display: inline-block;
    padding: 10px 20px;
    white-space: nowrap;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-item-has-children {
    position: relative;
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-item-has-children ul.hs-menu-children-wrapper {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    position: absolute;
    z-index: 10;
    left: 0;
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-item-has-children ul.hs-menu-children-wrapper li a {
    display: block;
    white-space: nowrap;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-item-has-children ul.hs-menu-children-wrapper li.hs-item-has-children ul.hs-menu-children-wrapper {
    left: 180px;
    top: 0;
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-item-has-children:hover > ul.hs-menu-children-wrapper {
    opacity: 1;
    visibility: visible;
}

.row-fluid-wrapper:last-child .hs-menu-wrapper.hs-menu-flow-horizontal > ul {
    margin-bottom: 0;
}

.hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch {
    position: relative;
    margin-bottom: 20px;
    min-height: 7em;
}

.hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch > ul {
    margin-bottom: 0;
}

.hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch > ul li.hs-item-has-children {
    position: static;
}

.hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch > ul li.hs-item-has-children ul.hs-menu-children-wrapper {
    display: none;
}

.hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch > ul li.hs-item-has-children.active-branch > ul.hs-menu-children-wrapper {
    display: block;
    visibility: visible;
    opacity: 1;
}

.hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch > ul li.hs-item-has-children.active-branch > ul.hs-menu-children-wrapper:before {
    content: " ";
    display: table;
}

.hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch > ul li.hs-item-has-children.active-branch > ul.hs-menu-children-wrapper:after {
    content: " ";
    display: table;
    clear: both;
}

.hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch > ul li.hs-item-has-children.active-branch > ul.hs-menu-children-wrapper > li {
    float: left;
}

.hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch > ul li.hs-item-has-children.active-branch > ul.hs-menu-children-wrapper > li a {
    display: inline-block;
    padding: 10px 20px;
    white-space: nowrap;
    max-width: 140px;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.hs-menu-wrapper.hs-menu-flow-vertical {
    width: 100%;
}

.hs-menu-wrapper.hs-menu-flow-vertical ul {
    list-style: none;
    margin: 0;
}

.hs-menu-wrapper.hs-menu-flow-vertical li a {
    display: block;
    white-space: nowrap;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hs-menu-wrapper.hs-menu-flow-vertical > ul {
    margin-bottom: 20px;
}

.hs-menu-wrapper.hs-menu-flow-vertical > ul li.hs-menu-depth-1 > a {
    width: auto;
}

.hs-menu-wrapper.hs-menu-flow-vertical > ul li a {
    padding: 10px 20px;
    text-decoration: none;
}

.hs-menu-wrapper.hs-menu-flow-vertical > ul li.hs-item-has-children {
    position: relative;
}

.hs-menu-wrapper.hs-menu-flow-vertical > ul li.hs-item-has-children ul.hs-menu-children-wrapper {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 180px;
}

.hs-menu-wrapper.hs-menu-flow-vertical > ul li.hs-item-has-children:hover > ul.hs-menu-children-wrapper {
    opacity: 1;
    visibility: visible;
}

.hs-menu-wrapper.hs-menu-flow-vertical.hs-menu-show-active-branch li a {
    min-width: 140px;
    width: auto;
}

.hs-menu-wrapper.hs-menu-flow-vertical.hs-menu-show-active-branch > ul {
    max-width: 100%;
    overflow: hidden;
}

.hs-menu-wrapper.hs-menu-flow-vertical.hs-menu-show-active-branch > ul li.hs-item-has-children ul.hs-menu-children-wrapper {
    display: none;
}

.hs-menu-wrapper.hs-menu-flow-vertical.hs-menu-show-active-branch > ul li.hs-item-has-children.active-branch > ul.hs-menu-children-wrapper,
.hs-menu-wrapper.hs-menu-flow-vertical.hs-menu-show-active-branch > ul li.hs-item-has-children.active-branch > ul.hs-menu-children-wrapper > li.hs-item-has-children > ul.hs-menu-children-wrapper {
    display: block;
    visibility: visible;
    opacity: 1;
    position: static;
}

.hs-menu-wrapper.hs-menu-flow-vertical.hs-menu-show-active-branch li.hs-menu-depth-2 > a {
    padding-left: 4em;
}

.hs-menu-wrapper.hs-menu-flow-vertical.hs-menu-show-active-branch li.hs-menu-depth-3 > a {
    padding-left: 6em;
}

.hs-menu-wrapper.hs-menu-flow-vertical.hs-menu-show-active-branch li.hs-menu-depth-4 > a {
    padding-left: 8em;
}

.hs-menu-wrapper.hs-menu-flow-vertical.hs-menu-show-active-branch li.hs-menu-depth-5 > a {
    padding-left: 140px;
}

.hs-menu-wrapper.hs-menu-not-show-active-branch li.hs-menu-depth-1 ul {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.18);
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.18);
}

@media (max-width: 568px) {
    .hs-menu-wrapper, .hs-menu-wrapper * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        width: 100%;
    }

    .hs-menu-wrapper.hs-menu-flow-horizontal ul {
        list-style: none;
        margin: 0;
        display: block;
    }

    .hs-menu-wrapper.hs-menu-flow-horizontal > ul {
        display: block;
    }

    .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-menu-depth-1 {
        float: none;
    }

    .hs-menu-wrapper.hs-menu-flow-horizontal > ul li a,
    .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-item-has-children ul.hs-menu-children-wrapper li a,
    .hs-menu-wrapper.hs-menu-flow-horizontal.hs-menu-show-active-branch > ul li.hs-item-has-children.active-branch > ul.hs-menu-children-wrapper > li a {
        display: block;
        max-width: 100%;
        width: 100%;
    }
}

.hs-menu-wrapper.hs-menu-flow-vertical.no-flyouts > ul li.hs-item-has-children ul.hs-menu-children-wrapper,
.hs-menu-wrapper.hs-menu-flow-horizontal.no-flyouts > ul li.hs-item-has-children ul.hs-menu-children-wrapper {
    visibility: visible;
    opacity: 1;
}

/* Space Module */

.widget-type-space {
    visibility: hidden;
}

/* Blog Author Section */

.hs-author-listing-header {
    margin: 0 0 .75em 0;
}

.hs-author-social-links {
    display: inline-block;
}

.hs-author-social-links a.hs-author-social-link {
    width: 24px;
    height: 24px;
    border-width: 0px;
    border: 0px;
    line-height: 24px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    display: inline-block;
    text-indent: -99999px;
}

.hs-author-social-links a.hs-author-social-link.hs-social-facebook {
    background-image: url("//static.hubspot.com/final/img/common/icons/social/facebook-24x24.png");
}

.hs-author-social-links a.hs-author-social-link.hs-social-linkedin {
    background-image: url("//static.hubspot.com/final/img/common/icons/social/linkedin-24x24.png");
}

.hs-author-social-links a.hs-author-social-link.hs-social-twitter {
    background-image: url("//static.hubspot.com/final/img/common/icons/social/twitter-24x24.png");
}

.hs-author-social-links a.hs-author-social-link.hs-social-google-plus {
    background-image: url("//static.hubspot.com/final/img/common/icons/social/googleplus-24x24.png");
}

/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

/* Wrapper */
.hs_cos_wrapper_type_image_slider {
    display: block;
    overflow: hidden
}

/* Browser Resets */
.hs_cos_flex-container a:active,
.hs_cos_flex-slider a:active,
.hs_cos_flex-container a:focus,
.hs_cos_flex-slider a:focus  {outline: none;}
.hs_cos_flex-slides,
.hs_cos_flex-control-nav,
.hs_cos_flex-direction-nav {margin: 0; padding: 0; list-style: none;}

/* FlexSlider Necessary Styles
*********************************/
.hs_cos_flex-slider {margin: 0; padding: 0;}
.hs_cos_flex-slider .hs_cos_flex-slides > li {display: none; -webkit-backface-visibility: hidden; position: relative;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.hs_cos_flex-slider .hs_cos_flex-slides img {width: 100%; display: block; border-radius: 0px;}
.hs_cos_flex-pauseplay span {text-transform: capitalize;}

/* Clearfix for the .hs_cos_flex-slides element */
.hs_cos_flex-slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}
html[xmlns] .hs_cos_flex-slides {display: block;}
* html .hs_cos_flex-slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */

/* FlexSlider Default Theme
*********************************/
.hs_cos_flex-slider {margin: 0 0 60px; background: #fff; border: 0; position: relative; -webkit-border-radius: 4px; -moz-border-radius: 4px; -o-border-radius: 4px; border-radius: 4px; zoom: 1;}
.hs_cos_flex-viewport {max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; transition: all 1s ease;}
.loading .hs_cos_flex-viewport {max-height: 300px;}
.hs_cos_flex-slider .hs_cos_flex-slides {zoom: 1;}

.carousel li {margin-right: 5px}


/* Direction Nav */
.hs_cos_flex-direction-nav {*height: 0;}
.hs_cos_flex-direction-nav a {width: 30px; height: 30px; margin: -20px 0 0; display: block; background: url(http://cdn2.hubspotqa.com/local/hub/124/file-52894-png/bg_direction_nav.png) no-repeat 0 0; position: absolute; top: 50%; z-index: 10; cursor: pointer; text-indent: -9999px; opacity: 0; -webkit-transition: all .3s ease;}
.hs_cos_flex-direction-nav .hs_cos_flex-next {background-position: 100% 0; right: -36px; }
.hs_cos_flex-direction-nav .hs_cos_flex-prev {left: -36px;}
.hs_cos_flex-slider:hover .hs_cos_flex-next {opacity: 0.8; right: 5px;}
.hs_cos_flex-slider:hover .hs_cos_flex-prev {opacity: 0.8; left: 5px;}
.hs_cos_flex-slider:hover .hs_cos_flex-next:hover, .hs_cos_flex-slider:hover .hs_cos_flex-prev:hover {opacity: 1;}
.hs_cos_flex-direction-nav .hs_cos_flex-disabled {opacity: .3!important; filter:alpha(opacity=30); cursor: default;}

/* Direction Nav for the Thumbnail Carousel */
.hs_cos_flex_thumbnavs-direction-nav {
    margin: 0px;
    padding: 0px;
    list-style: none;
    }
.hs_cos_flex_thumbnavs-direction-nav {*height: 0;}
.hs_cos_flex_thumbnavs-direction-nav a {width: 30px; height: 140px; margin: -60px 0 0; display: block; background: url(http://cdn2.hubspotqa.com/local/hub/124/file-52894-png/bg_direction_nav.png) no-repeat 0 40%; position: absolute; top: 50%; z-index: 10; cursor: pointer; text-indent: -9999px; opacity: 1; -webkit-transition: all .3s ease;}
.hs_cos_flex_thumbnavs-direction-nav .hs_cos_flex_thumbnavs-next {background-position: 100% 40%; right: 0px; }
.hs_cos_flex_thumbnavs-direction-nav .hs_cos_flex_thumbnavs-prev {left: 0px;}
.hs-cos-flex-slider-control-panel img { cursor: pointer; }
.hs-cos-flex-slider-control-panel img:hover { opacity:.8; }
.hs-cos-flex-slider-control-panel { margin-top: -30px; }



/* Control Nav */
.hs_cos_flex-control-nav {width: 100%; position: absolute; bottom: -40px; text-align: center;}
.hs_cos_flex-control-nav li {margin: 0 6px; display: inline-block; zoom: 1; *display: inline;}
.hs_cos_flex-control-paging li a {width: 11px; height: 11px; display: block; background: #666; background: rgba(0,0,0,0.5); cursor: pointer; text-indent: -9999px; -webkit-border-radius: 20px; -moz-border-radius: 20px; -o-border-radius: 20px; border-radius: 20px; box-shadow: inset 0 0 3px rgba(0,0,0,0.3);}
.hs_cos_flex-control-paging li a:hover { background: #333; background: rgba(0,0,0,0.7); }
.hs_cos_flex-control-paging li a.hs_cos_flex-active { background: #000; background: rgba(0,0,0,0.9); cursor: default; }

.hs_cos_flex-control-thumbs {margin: 5px 0 0; position: static; overflow: hidden;}
.hs_cos_flex-control-thumbs li {width: 25%; float: left; margin: 0;}
.hs_cos_flex-control-thumbs img {width: 100%; display: block; opacity: .7; cursor: pointer;}
.hs_cos_flex-control-thumbs img:hover {opacity: 1;}
.hs_cos_flex-control-thumbs .hs_cos_flex-active {opacity: 1; cursor: default;}

@media screen and (max-width: 860px) {
  .hs_cos_flex-direction-nav .hs_cos_flex-prev {opacity: 1; left: 0;}
  .hs_cos_flex-direction-nav .hs_cos_flex-next {opacity: 1; right: 0;}
}

.hs_cos_flex-slider .caption {
    background-color: black;
    position: static;
    font-size: 2em;
    line-height: 1.1em;
    color: white;
    padding: 0px 5% 0px 5%;
    width: 90%;
    top: 40%;
    text-align: center;
}

.hs_cos_flex-slider .superimpose .caption {
    color: white;
    font-size: 3em;
    line-height: 1.1em;
    position: absolute;
    padding: 0px 5% 0px 5%;
    width: 90%;
    top: 40%;
    text-align: center;
    background-color: transparent;
}

@media all and (max-width: 400px) {
    .hs_cos_flex-slider .superimpose .caption {
        background-color: black;
        position: static;
        font-size: 2em;
        line-height: 1.1em;
        color: white;
        width: 90%;
        padding: 0px 5% 0px 5%;
        top: 40%;
        text-align: center;
    }

    /* beat recaptcha into being responsive, !importants and specificity are necessary */
    #recaptcha_area table#recaptcha_table {width: 300px !important;}
    #recaptcha_area table#recaptcha_table .recaptcha_r1_c1 {width: 300px !important;}
    #recaptcha_area table#recaptcha_table .recaptcha_r4_c4 { width: 67px !important;}
    #recaptcha_area table#recaptcha_table #recaptcha_image {width:280px !important;}
}

.hs_cos_flex-slider h1,
.hs_cos_flex-slider h2,
.hs_cos_flex-slider h3,
.hs_cos_flex-slider h4,
.hs_cos_flex-slider h5,
.hs_cos_flex-slider h6,
.hs_cos_flex-slider p {
    color: white;
}

/* Thumbnail only version of the gallery */
.hs-gallery-thumbnails li {
    display: inline-block;
    margin: 0px;
    padding: 0px;
    margin-right:-4px;
}
.hs-gallery-thumbnails.fixed-height li img {
    max-height: 150px;
    margin: 0px;
    padding: 0px;
    border-width: 0px;
}


/* responsive pre elements */

pre {
    overflow-x: auto;
}

/* responsive pre tables */

table pre {
    white-space: pre-wrap;
}

/* adding minimal spacing for blog comments */
.comment {
    margin: 10px 0 10px 0;
}

/* make sure lines with no whitespace don't interefere with layout */
.hs_cos_wrapper_type_rich_text,
.hs_cos_wrapper_type_text,
.hs_cos_wrapper_type_header,
.hs_cos_wrapper_type_section_header,
.hs_cos_wrapper_type_raw_html,
.hs_cos_wrapper_type_raw_jinja,
.hs_cos_wrapper_type_page_footer {
    word-wrap: break-word;
}