.consultant-info {
  width: 100%;
  margin-bottom: em(30);
  border: 2px solid #E3E1DD;
  background-color: $gray-light;
  padding: em(44) em(40) em(48);
  text-align: center;
  .consultant-image {
    width: em(88);
    height: em(88);
    border-radius: 50%;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border: 2px solid $gold;
    margin: 0 auto em(8);
  }
  h2 {
    color: $gray-dark;
    margin-top: 0;
  }
  h4 {
    font-size: em(20);
    color: $gold;
    font-weight: 300;
    line-height: (24 / 20);
    text-align: center;
    display: block;
    margin-bottom: 0;
  }
  hr {
    margin: em(38) auto em(22);
  }
  p {
    font-size: em(20);
    line-height: (32 / 20);
    font-weight: 300;
    color: $gray-dark;
    margin-top: 0;
    margin-bottom: em(40);
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    a.gold {
      display: block;
    }
  }
}