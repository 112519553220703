.mj-home {
  .feature-box-nest {
    margin-bottom: em(50);
    @media (min-width: 768px) {
      margin: 0 -10px em(50);
      .span6 {
        width: 50%;
        margin-left: 0;
      }
      .span8 {
        width: 66.66666666%;
        margin-left: 0;
      }
      .span4 {
        width: 33.333333333%;
        margin-left: 0;
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      .top-portion {
        .span4, .span8 {
          width: 50% !important;
        }
      }
      .tablet-expand .feature-box {
        margin-left: -100%;
        width: 200%;
        padding-bottom: 68.5%;
      }
      .feature-box.full {
        padding-bottom: 155.18%;
      }
    }
    .bottom-portion {
      @media (min-width: 768px) and (max-width: 991px) {
        .span4 {
          &:nth-child(1), &:nth-child(2) {
            width: 50% !important;
          }
          &:nth-child(3) {
            width: 100% !important;
          }
        }
      }
    }
    .top-right-half {
      @media (max-width: 991px) {
        .span6 {
          width: 100% !important;
        }
      }
    }
  }
  .actions {
    text-align: center;
    a {
      @media (max-width: 767px) {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}