nav.large-share {
  ul {
    list-style-type: none;
    margin: 0;
    padding: em(24) 0 em(10) 0;
    text-align: center;
    li {
      display: inline-block;
      margin: 0 em(11);
      a {
        width: em(77.75);
        height: em(77.75);
        background-color: white;
        border: 2px solid $gray-light;
        border-radius: 50%;
        position: relative;
        text-align: center;
        display: block;
        transition: 0.25s background-color ease-in-out;
        &:hover {
          background-color: $gray-lighter;
          img {
            opacity: 0.95;
          }
        }
        img {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          height: em(38.88);
          width: auto;
          margin: auto;
          opacity: 1;
          transition: 0.25s opacity ease-in-out;
        }
      }
    }
  }
}

.footer-subscribe {
  text-align:center;
  h3 {
    margin-bottom:em(32,29);
    img {
      width:em(34,29);
      height:em(41,29);
    }
  }
  form {
    width:100%;
    max-width:em(616);
    padding-bottom:em(40);
    margin:auto;
    position:relative;
    > div {
      display:inline-block;
      &:first-child {
        width:60%;
      }
    }
    label {
      display:none;
    }
    input[type="email"] {
      background-color: rgba(#fff, 0.5);
      border:1px solid $gold;
      border-radius:0;
      padding:13px 0!important;
      @media (max-width:767px) {
        margin-bottom:10px;
      }
    }
    input[type="submit"] {
      @extend .cta-btn;
      letter-spacing:em(2,18);
    }
  }
}