@mixin section-border($width,$edge,$min,$max) {
	
	@if $edge == 'left' {
	  	@include media-min-max($min,$max) {
			border-left: $width solid $brand-navy;
		}
	}
	@else if $edge == 'right' {
	  	@include media-min-max($min,$max) {
			border-right: $width solid $brand-navy;
		}
	}
	@else if $edge == 'top' {
	  	@include media-min-max($min,$max) {
			border-top: $width solid $brand-navy;
			@include media-max(screen-md) {}
		}
	}
	@else if $edge == 'bottom' {
	 	@include media-min-max($min,$max) {
			border-bottom: $width solid $brand-navy;
			@include media-max(screen-md) {}
		}
	}	
}
	
@mixin menu-size($height) {
	
	.site-header-container {height: $height;}
	
	height:$height;
	
	.main-nav {
		height:$height;
		.hs-menu-wrapper {
			&.hs-menu-flow-horizontal {
				& > ul > li > a {
					line-height:$height - 4px;
				}
			}
		}
	}
	
	.site-logo {
		height:$height; 
		line-height:$height; 			
	}	
	
	.search-trigger-container,
	.menu-trigger-container {
		top: $height/4;
	}
}

@mixin responsive-width {
	width:auto;	margin-left:auto !important; margin-right:auto !important; float:none !important; 	
	@include transition(.3s);	
	
	@include media(screen-lg) {width:$responsive-sheet-width;}
}

@mixin responsive-width-large {
	width:auto;	margin-left:auto !important; margin-right:auto !important; float:none !important; 	
	@include transition(.3s);	
	
	@media (min-width: $responsive-sheet-width-large + 100) {
		width:$responsive-sheet-width-large !important;
	}
}

// Text Alignment
.center-xs {
	@media (max-width: $screen-xs-min) {
		text-align: center;
	}
}
.center-sm {
	@media (max-width: $screen-sm-min) {
		text-align: center;
	}
}
.center-md {
	@media (max-width: $screen-md-min) {
		text-align: center;
	}
}


// Vertical Padding Classes
.section-padding-no {padding-top:0 !important; padding-bottom:0 !important;}
.section-padding-top-no {padding-top:0 !important;}
.section-padding-bottom-no {padding-bottom:0 !important;}

.section-padding-lg {
	padding-top:em($section-padding-sm);
	padding-bottom:em($section-padding-sm);
	
	@include media(screen-sm) {
		padding-top:em($section-padding-sm);
		padding-bottom:em($section-padding-sm);
	}
	@include media(screen-md) {
		padding-top:em($section-padding-md);
		padding-bottom:em($section-padding-md);
	}
	@include media(screen-lg) {
		padding-top:em($section-padding-lg);
		padding-bottom:em($section-padding-lg);
	}	
}
.section-padding-md {
	padding-top:$section-padding-xs;
	padding-bottom:$section-padding-xs;
	
	@include media(screen-sm) {
		padding-top:em($section-padding-sm);
		padding-bottom:em($section-padding-sm);
	}
	@include media(screen-md) {
		padding-top:em($section-padding-md);
		padding-bottom:em($section-padding-md);
	}
	@include media(screen-lg) {
		padding-top:em($section-padding-md);
		padding-bottom:em($section-padding-md);
	}	
}
.section-padding-sm {
	padding-top:$section-padding-xs;
	padding-bottom:$section-padding-xs;
	
	@include media(screen-sm) {
		padding-top:em($section-padding-sm);
		padding-bottom:em($section-padding-sm);
	}
	@include media(screen-md) {
		padding-top:em($section-padding-sm);
		padding-bottom:em($section-padding-sm);
	}
	@include media(screen-lg) {
		padding-top:em($section-padding-sm);
		padding-bottom:em($section-padding-sm);
	}	
}
.section-padding-xs {
	padding-top:$section-padding-xs;
	padding-bottom:$section-padding-xs;
	
	@include media(screen-sm) {
		padding-top:$section-padding-xs;
		padding-bottom:$section-padding-xs;
	}
	@include media(screen-md) {
		padding-top:$section-padding-xs;
		padding-bottom:$section-padding-xs;
	}
	@include media(screen-lg) {
		padding-top:$section-padding-xs;
		padding-bottom:$section-padding-xs;
	}	
}

.section-optional {
	&, & > .row-fluid-wrapper {
		@extend .section-padding-no;
	}	
	
	&.section-padding-lg {	
		& > .row-fluid-wrapper .row-fluid .widget-span span > .hs_cos_wrapper {
			@extend .section-padding-lg;
		}
	}
	&.section-padding-md {		
		& > .row-fluid-wrapper .row-fluid .widget-span span > .hs_cos_wrapper {
			@extend .section-padding-md;
		}
	}
	&.section-padding-sm {
		& > .row-fluid-wrapper .row-fluid .widget-span span > .hs_cos_wrapper {
			@extend .section-padding-sm;
		}
	}
	&.section-padding-xs {
		& > .row-fluid-wrapper .row-fluid .widget-span span > .hs_cos_wrapper {
			@extend .section-padding-xs;
		}
	}
	&.section-padding-top-no {
		& > .row-fluid-wrapper .row-fluid .widget-span span > .hs_cos_wrapper {
			@extend .section-padding-top-no;
		}
	}
	&.section-padding-bottom-no {
		& > .row-fluid-wrapper .row-fluid .widget-span span > .hs_cos_wrapper {
			@extend .section-padding-bottom-no;
		}
	}
}


// Horizontal Padding Classes
.padded-content-tablet {
	@include media(screen-xs) {
		padding-left:$padded-content-lg !important;
		padding-right:$padded-content-lg !important;
	}
	@include media(screen-sm) {
		padding-left:$padded-content-md !important;
		padding-right:$padded-content-md !important;		
	}
	@include media(screen-md) {
		padding-left:$padded-content-no !important;
		padding-right:$padded-content-no !important;		
	}	
}

.margin-content-xl {
	margin-left:$padded-content-no !important;
	margin-right:$padded-content-no !important;
	
	//@include media(screen-sm) {
	//	margin-left:$padded-content-md !important;
	//	margin-right:$padded-content-md !important;
	//}
	@include media(screen-md) {
		margin-left:$padded-content-lg !important;
		margin-right:$padded-content-lg !important;
	}
	@include media(screen-lg) {
		margin-left:$padded-content-xl !important;
		margin-right:$padded-content-xl !important;
	}	
}

.padded-content-xl {
	//padding-left:$padded-content-md !important;
	//padding-right:$padded-content-md !important;
	
	@include media(screen-sm) {
		padding-left:$padded-content-lg !important;
		padding-right:$padded-content-lg !important;
	}
	@include media(screen-md) {
		padding-left:$padded-content-lg !important;
		padding-right:$padded-content-lg !important;
	}
	@include media(screen-lg) {
		padding-left:$padded-content-xl !important;
		padding-right:$padded-content-xl !important;
	}
}

.padded-content-lg {
	//padding-left:$padded-content-md !important;
	//padding-right:$padded-content-md !important;
	
	@include media(screen-sm) {
		padding-left:$padded-content-md !important;
		padding-right:$padded-content-md !important;
	}
	@include media(screen-md) {
		padding-left:$padded-content-lg !important;
		padding-right:$padded-content-lg !important;
	}
	@include media(screen-lg) {
		padding-left:$padded-content-lg !important;
		padding-right:$padded-content-lg !important;
	}
}
.padded-content-md {
	//padding-left:$padded-content-sm !important;
	//padding-right:$padded-content-sm !important;
	
	@include media(screen-sm) {
		padding-left:$padded-content-sm !important;
		padding-right:$padded-content-sm !important;
	}
	@include media(screen-md) {
		padding-left:$padded-content-md !important;
		padding-right:$padded-content-md !important;
	}
	@include media(screen-lg) {
		padding-left:$padded-content-md !important;
		padding-right:$padded-content-md !important;
	}
}
.padded-content-sm {
	//padding-left:$padded-content-xs !important;
	//padding-right:$padded-content-xs !important;
	
	@include media(screen-sm) {
		padding-left:$padded-content-sm !important;
		padding-right:$padded-content-sm !important;
	}
	@include media(screen-md) {
		padding-left:$padded-content-sm !important;
		padding-right:$padded-content-sm !important;
	}
	@include media(screen-lg) {
		padding-left:$padded-content-sm !important;
		padding-right:$padded-content-sm !important;
	}
}
.padded-content-xs {
	padding-left:$padded-content-xs !important;
	padding-right:$padded-content-xs !important;
}

.padded-content-no {padding-left:0 !important; padding-right:0 !important;}
.padded-content-left-no {padding-left:0 !important;}
.padded-content-right-no {padding-right:0 !important;}