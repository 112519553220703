.interior-billboard {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  &.featured-image {
    height:em(450);
    @media (max-width:767px) {
      height:20em !important;
    }
  }
  .img-underlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .gradient {
    position: absolute;
    height: 100%;
    width: 58%;
    top: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    z-index: 2;
    background-image: url("//cdn2.hubspot.net/hub/218371/file-3297385646-png/assets/images/billboard-gradient.png");
    background-position: left top;
    @media (max-width: 767px) {
      width: 200%;
    }
    @media (min-width: 768px) {
      width: 58%;
    }
  }
  .constrain {
    width: 100%;
    max-width: 1350px;
    margin: auto;
    text-align: left;
    padding: em(136) em(20) em(83);
    .inner {
      position: relative;
      z-index: 3;
    }
  }
}