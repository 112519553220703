//------------------------------------
//FOOTER
//------------------------------------

.footer-container-wrapper {}

.footer-container {}

.site-footer {
  background-color: #444;
  text-align: center;
  display: block;
  padding: em(25) em(20);
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  .row-fluid {
    width: 100%;
    max-width: 1380px;
    margin: auto;
    float: none;
    p {
      margin-bottom: 0;
      font-size: em(14);
      font-weight: 500;
      line-height: (17.5 / 14);
      color: #BBB2A6;
      text-transform: uppercase;
      letter-spacing: em(1.75, 14);
      text-align: center;
    }
  }
}

.footer-area {
  hr {
    max-width: 100%;
  }
  h3 {
    text-align: center;
    margin-top: em(10, 29);
    margin-bottom: 0;
    font-size: em(29);
    img {
      position: relative;
      top: em(4, 29);
    }
  }
}