.mj-blog {
  .blog-wrapper {
    text-align: center;
    padding: em(100) 15px;
    .constrain > .row-fluid-wrapper > .row-fluid {
      width: 100%;
      max-width: 1370px;
      margin: auto;
      text-align: left;
      float: none;
      .span8 {
      	@media (min-width:768px) and (max-width:991px) {
      		width:100% !important;
      	}
      }
      .span4 {
      	@media (min-width:768px) and (max-width:991px) {
      		width:100% !important;
      		margin-left:0 !important;
      	}
      	@media (min-width:992px) {
      		margin-left:0.8% !important;
      		width:33.2% !important;
      	}
      }
    }
  }
  .listing-wrapper {
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
  .blog-post-link {
    @extend .col-xs-12;
    @extend .col-sm-6;
    margin-bottom:em(22);
    &:hover, &:focus {
      text-decoration: none;
      .blog-post {
        background-color: $gray-lighter;
      }
      .featured-image {
        opacity: 0.9;
      }
    }
  }
  .blog-post {
    background-color: $gray-light;
    transition: 0.25s background-color ease-in-out;
    position: relative;
    &:not(.has-featured-image) .featured-image {
      display: none;
    }
    .featured-image {
      width: 100%;
      height: 0;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      opacity: 1;
      transition: 0.25s opacity ease-in-out;
      @media (max-width:767px) {
      	padding-bottom:50%;
      }
      @media (min-width:768px) {
      	padding-bottom: 76.2%;
      }
    }
    .main {
      padding: em(30) em(48) em(33);
      position:relative;
    }

    .name {
      font-family: $font-family-serif;
      font-size: em(32);
      line-height: (38 / 32);
      color: $gray-dark;
    }
    .read-more {
      font-size: em(18);
      font-weight: 300;
      text-transform: uppercase;
      transition: 0.25s color ease-in-out;
      @media (max-width:1399px) {
      	position:relative;
      	display:block;
      }
      @media (min-width:1400px) {
      	position: absolute;
	      bottom: em(38, 18);
	      right: em(58, 18);
      }
    }
    h3 {
      font-size: em(20);
      line-height: (32 / 20);
      font-weight: 300;
      color: $gray;
      margin-top: 0;
    }
  }
  .blog-right-sidebar {
    > .row-fluid-wrapper > .row-fluid {
      > .widget-span {
        background-color: $gray-light;
        border: 2px solid #E3E1DD;
        margin-bottom: em(22);
        width: 100%;
        padding: em(63) 19%;
        text-align: center;
        p {
          text-align: left;
          font-size: em(18);
          line-height: (26 / 18);
          font-weight: 300;
          color: $gray;
          margin-bottom:em(30,18);
        }
        h3 {
        	font-weight:400;
        	color:$pink-dark;
        	font-size:em(24);
        	line-height:(50 / 24);
        	margin:0 auto em(5,24);
        }
      }
    }
    .filter-expand-link {
    	@extend .cta-btn-transparent;
    	@extend .cta-btn-transparent.gold;
    	&:hover, &:focus {
    		background-color:$gold !important;
    	}
    }
    .form-title, .filter-link-count {
      display: none;
    }
    .widget-module ul {
    	list-style-type:none;
    	margin:0 0 em(30);
    	padding:0;
    	li {
    		text-align:center;
    		display:block;
    		a {
    			font-size:em(20);
    			line-height:(24 / 20);
    			font-weight:400;
    			color:$gray;
    			transition:0.25s color ease-in-out;
    			&:hover, &:focus {
    				text-decoration:none;
    				color:$pink-dark;
    			}
    		}
    	}
    }
    h2 {
    	margin-top:0;
    }
    form {
    	text-align:left;
    	label {
    		display:none;
    	}
    	input[type="email"] {
				margin-bottom:em(30,20);
				background-color:rgba(#fff, 0.5);
				border:1px solid $gold;
				font-size:em(20);
				padding:em(4.5,20) em(11,20) !important;
    	}
    	input[type="submit"] {
    		@extend .cta-btn;
    		display:block;
    		width:100%;
    		margin:0 !important;
    	}
    }
  }
  .post-content {
  	.header {
  		h2 {
  			text-align:center;
  			margin-top:em(30,48);
  			&, span {
	  			font-family:$font-family-sans-serif !important;
	  			line-height:(54 / 46);
	  			font-weight:300;
	  		}
	  		span {
	  			font-size:1em;
	  		}
  			a {
  				color:$pink-dark;
  				transition:0.25s color ease-in-out;
  				&:hover {
  					text-decoration:none;
  					color:$pink-light;
  				}
  			}
  		}
  		.byline {
  			text-align:center;
  			font-family:$font-family-serif;
				.author-link {
					font-size:em(37.89);
					line-height:(41 / 37.89);
					color:$gray-dark;
					transition:0.25s color ease-in-out;
					&:hover, &:focus {
						color:$pink-dark;
						text-decoration:none;
					}
					img {
						width:em(41,37.89);
						height:em(41,37.89);
						border-radius:50%;
						display:inline-block;
						vertical-align:middle;
						margin-right:em(9,37.89);
					}
				}
  		}
  	}
  	.body {
  		h3 {
  			color:$pink-dark;
  			font-size:em(29);
  		}
  		p {
  			font-weight:300;
  			font-size:em(18);
  			line-height:2;
  			margin-bottom:em(14,18);
  		}
  		img {
	  		max-width:none;
	  		width:calc(100% - -230px);
	  		@media (max-width:767px) {
	  			max-width:100%;
	  			margin:em(46) auto;
	  		}
	  		@media (min-width:768px) {
	  			max-width:none;
	  			margin:em(46) -115px;
	  		}
	  	}
  	}
  	.footer {
  		text-align:center;
  		.cta-btn-transparent {
  			letter-spacing:em(3,14);
  			margin:em(50,14) auto;
  			display:inline-block;
  		}
  		.about-author, .related-posts {
				max-width:none;
	  		width:calc(100% - -230px);
	  		@media (max-width:767px) {
	  			max-width:100%;
	  			margin:em(46) auto;
	  		}
	  		@media (min-width:768px) {
	  			max-width:none;
	  			margin:em(46) -115px;
	  		}
  		}
  		.about-author {
  			border:2px solid #BBB2A6;
  			padding:em(50) 12.5%;
  			&:after {
  				content:'';
  				clear:both;
  				display:block;
  			}
  			p {
  				font-size:em(22);
  				line-height:2;
  				font-weight:200;
  			}
  			h4 {
  				font-family:$font-family-serif;
  				line-height:em(55,37.89);
  				font-size:em(37.89);
  				img {
  					display:inline-block;
  					vertical-align:middle;
  					width:em(55,37.89);
  					height:em(55,37.89);
  					border-radius:50%;
  					margin-right:em(12,37.89);
  				}
  				a {
  					color:$gray-dark;
						display:inline-block;
						vertical-align:middle;
						transition:0.25s color ease-in-out;
						&:hover, &:focus {
							text-decoration:none;
							color:$pink-dark;
						}
  				}
  			}
  		}
  		.related-posts {
				h3 {
					font-size:em(29);
					color:$gray;
					font-weight:300;
					margin-bottom:em(26, 29);
					text-align:center;
					margin-top:0;
				}
  		}
  		.post-item {
  			width:100%;
  			background-color:$gray-light;
  			text-align:left;
  			margin-bottom:em(21);
  			.post-header {
  				padding:em(15) em(23);
  			}
  			.hs-featured-image-wrapper {
  				padding-bottom:77%;
  				display:block;
  				width:100%;
  				height:0;
  				position:relative;
  				background-size:cover;
  				background-position:center top;
  				a {
						position:absolute;
						width:100%;
						height:100%;
						top:0;
						bottom:0;
						left:0;
						right:0;
						opacity:0;
						background-color:#000;
						transition:0.25s opacity ease-in-out;
						&:hover {
							opacity:0.1;
						}
  				}
  			}
  			a {
  				color:$gray;
  				font-size:em(16);
  				line-height:(24 / 16);
  				font-weight:300;
  				transition:0.25s color ease-in-out;
  				&:hover, &:focus {
  					color:$pink-dark;
  					text-decoration:none;
  				}
  			}
  		}
  	}
  	#hubspot-topic_data {
  		color:$gray;
  		font-size:em(20);
  		line-height:1.3;
  		padding-bottom:em(30,20);
  		span {
  			font-size:em(24,20);
  			line-height:(50 / 24);
  			margin-bottom:em(10,24);
  			margin:auto;
  			display:block;
  			color:$pink-dark;
  		}
  		a {
  			color:$gray;
  			&:hover, &:focus {
  				color:$pink-dark;
  			}
  		}
  	}
  }
  .blog-pagination {
  	text-align:center;
  	padding-top:em(22);
  	display:block;
  	@media (max-width:991px) {
  		padding-bottom:em(22);
  	}
  	a {
  		@extend .cta-btn-transparent;
  		letter-spacing:em(4,14);
  		@media (min-width:768px) {
  			width:212px;
				display:inline-block;
  		}
  		&:not(.active) {
  			opacity:0.5;
  			pointer-events:none;
  			cursor:default;
  		}
  	}
  }
  .section.post-footer {
		background-color:$gray-light;
		max-width:none;
		width:calc(100% - -230px);
		padding:em(50) 12.5%;
		@media (max-width:767px) {
			max-width:100%;
			margin:em(46) auto;
		}
		@media (min-width:768px) {
			max-width:none;
			margin:em(46) -115px;
		}
		h2 {
			text-align:center;
			display:block;
			font-size:em(59);
			line-height:(71 / 59);
			color:$gray;
			margin-top:0;
			margin-bottom:em(30,59);
		}
		input[type="checkbox"] {
			margin-right:5px;
		}
		input[type="text"], input[type="email"], textarea {
			background-color:rgba(#fff,0.5);
			border:1px solid $gold;
			font-size:em(20);
			padding:em(4.5,20) em(11,20) !important;
			margin-bottom:em(29,20);
			border-radius:0 !important;
		}
		label {
			display:none;
		}
		.actions {
			text-align:center;
			input[type="submit"] {
				@extend .cta-btn;
				display:inline-block;
			}
		}
  }
  .hs-blog-social-share {
    margin-top: 0;
		.hs-blog-social-share-list {
			text-align:center;
		}
    .hs-blog-social-share-item-google-plus > div, .hs-blog-social-share-item-twitter > iframe {
      position: relative !important;
      top: -2px;
    }
    .fb-like > span > iframe {
    	position:relative !important;
    	top:-6px;
    }
  }
  #comments-listing:not(.no-comments) {
  	border-bottom:3px solid #DDD8D2;
  	margin-bottom:em(30);
  	padding-bottom:em(22);
  	&:after {
  		display:block;
  		content:'';
  		clear:both;
  	}
  }
  #comment-form {
  	h3 {
  		text-align:center;
  		font-size:em(24);
  		line-height:(28 / 24);
  		color:$gold;
  		font-weight:300;
  		margin-top:0;
  		margin-bottom:em(26,24);
  	}
  }
  .comment {
  	border-top:3px solid #DDD8D2;
  	padding-top:em(30);
  	h4 a {
  		font-size:em(24);
  		color:$gold;
  		font-weight:300;
  	}
  	p {
  		font-size:em(18);
  		font-weight:300;
  		color:$gray;
  		line-height:2;
  	}
  	.comment-reply-to {
  		float:right;
  		color:$gold;
  		padding: 0 0 0 25px !important;
  	}
  }
}