.flexible-cta-region {
  text-align: center;
  &.pink {
    background-color: $pink-light;
  }
  > span > div {
    padding: em(102) em(20) em(95);
    width: 100%;
    margin: auto;
    float: none;
    max-width: 1380px;
    h1 {
      text-shadow: 0 em(1.33, 48) 0 rgba(#E87F6D, 1);
      margin: 0 auto em(37, 48);
      line-height: (57 / 48);
      font-size: em(48);
      color: white;
      font-weight: 300;
    }
  }
}