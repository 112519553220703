.mj-landing-page {
  .interior-billboard .constrain {
    padding-top: em(36);
    padding-bottom: em(50);
  }
  .main-content-full-width-header {
    margin-bottom: em(50);
    h1 {
      text-align: center;
    }
  }
  .main-content-area {
    padding-bottom: em(100);
    p {
      font-size: em(18);
      font-weight: 300;
      line-height: (32 / 18);
      margin-bottom: em(24, 18);
    }
  }
  .main-content-left-column {
    @media (min-width: 768px) {
      padding-right: 9%;
    }
    margin-bottom: em(40);
  }
  .hs_cos_wrapper_type_form {
    width: 100%;
    background-color: $gray-light;
    border: 2px solid #E3E1DD;
    padding: em(30) em(22);
    @media (max-width: 991px) {
      font-size: 13px;
    }
    .form-title {
      margin: 0;
    }
    label {
      display: none;
    }
    .hs-error-msgs label {
      color: #ff877a !important;
      font-size: 14px !important;
      font-weight: bold !important;
      display: block !important;
      margin: 0 !important;
    }
    input[type="text"], input[type="email"], input[type="tel"], input[type="password"], textarea {
      background-color: rgba(white, 0.5);
      font-size: em(20);
      color: $gold;
      font-weight: 400;
      padding: em(4, 20) em(10, 20) !important;
      border: 1px solid $gold;
      &:not([name='firstname']):not([name='lastname']) {
        margin-top: em(30, 20);
      }
    }
    textarea {
      font-size: em(16);
      padding: em(4, 16) em(10, 16) !important;
      margin-bottom: em(30, 16);
    }
    .actions {
      text-align: center;
      margin-top: em(-10);
      input[type="submit"] {
        background-color: $pink-dark;
        color: white;
        text-align: center;
        font-size: em(18);
        padding: em(10, 18) em(66, 18);
        letter-spacing: em(2, 18);
        margin: auto;
        box-shadow: 0 em(2, 18) 0 0 rgba(188, 95, 80, 1);
        transition: 0.25s background-color ease-in-out;
        &:hover, &:focus {
          text-decoration: none;
          color: white;
          background-color: darken($pink-dark, 5%);
        }
      }
    }
  }
}

::-webkit-input-placeholder {
  color: $gold;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $gold;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $gold;
}

:-ms-input-placeholder {
  color: $gold;
}