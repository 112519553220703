@mixin media($screen-size) {
  @if $screen-size == screen-xs {
    @media (min-width: $screen-xs-min) { @content; }
  }
  @else if $screen-size == screen-sm {
    @media (min-width: $screen-sm-min) { @content; }
  }
  @else if $screen-size == screen-md {
    @media (min-width: $screen-md-min) { @content; }
  }
  @else if $screen-size == screen-lg {
    @media (min-width: $screen-lg-min) { @content; }
  }
}

@mixin media-max($screen-size) {
  @if $screen-size == screen-xs {
    @media (max-width: $screen-xs-min - 1) { @content; }
  }
  @else if $screen-size == screen-sm {
    @media (max-width: $screen-sm-min - 1) { @content; }
  }
  @else if $screen-size == screen-md {
    @media (max-width: $screen-md-min - 1) { @content; }
  }
  @else if $screen-size == screen-lg {
    @media (max-width: $screen-lg-min - 1) { @content; }
  }	
}

@mixin media-min-max($screen-size-sm,$screen-size-lg) {
	@media (min-width: $screen-size-sm) and (max-width: $screen-size-lg) {
		@content;
	}
}