//------------------------------------
    //BILLBOARDS
//------------------------------------

$billboard-auto-height: auto;
$billboard-sm-height: em(25);
$billboard-md-height: em(28);
$billboard-lg-height: em(35);
$billboard-vh-height: 100vh;
.billboard-sm,
.billboard-md,
.billboard-lg,
.billboard-vh {
	position:relative;	
	
	&, .vert-content, 
	.full-width-section {
		height:$billboard-auto-height;
	}
	
	.vert-content-inner {
		padding-top:$site-header-height;
	}
	
	.full-width-section {
		z-index:1;
		
		.row-fluid-wrapper,
		.row-fluid {height:100%;}
	}	
}

.billboard-sm {
	@include media(screen-sm) {
		&, 
		.vert-content, 
		.full-width-section {
			height:$billboard-sm-height;
		}	
	}
}

.billboard-md {
	@include media(screen-sm) {
		&, 
		.vert-content, 
		.full-width-section {
			height:$billboard-md-height;
		}	
	}
}

.billboard-lg {	
	@include media(screen-sm) {
		&, 
		.vert-content, 
		.full-width-section {
			height:$billboard-lg-height;
		}	
	}
}

.billboard-vh {	
	@include media(screen-sm) {
		&, 
		.vert-content, 
		.full-width-section {
			height:$billboard-vh-height;
		}	
	}
}

.billboard-content {
	
}

.billboard-feature-image {
	.cell-wrapper {
		width:100%; 
		text-align:center; 
		position:absolute; 
		bottom:0; 
		left:0;
	}
}

.billboard-image {
	position:absolute; 
	top:0; 
	left:0; 
	width:100%; 
	height:100%; 
	z-index:0; 
	overflow:hidden;
	background-repeat:no-repeat; 
	background-position:center center; 
	background-size:cover;
}