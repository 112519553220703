.feature-box {
  width: 100%;
  display: block;
  position: relative;
  background-color: $gray-dark;
  height: 0;
  cursor: default;
  @media (max-width: 767px) {
    padding-bottom: 76.4%;
  }
  @media (min-width: 768px) {
    &.third {
      padding-bottom: 36.5%;
    }
    &.half {
      padding-bottom: 76.4%;
    }
    &.full {
      padding-bottom: 153.5%;
    }
  }
  @media (max-width: 991px) {
    border: 5px solid white;
  }
  @media (min-width: 992px) {
    border: 10px solid white;
  }
  &:hover, &:focus {
    text-decoration: none;
  }
  &:focus {
    outline: 0;
  }
  &.full {
    @media (min-width: 992px) and (max-width: 1100px) {
      padding-bottom: 155%;
    }
    @media (min-width: 1101px) and (max-width: 1400px) {
      padding-bottom: 154.5%;
    }
    @media (min-width: 1401px) {
      padding-bottom: 153.8%;
    }
  }
  figure {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    &:hover {
      .image-background {
        opacity: 0.9;
      }
      figcaption {
        color: $pink-dark;
      }
    }
  }
  .image-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    transition: 0.25s opacity ease-in-out;
  }
  figcaption {
    padding: em(20, 14);
    background-color: $gray-light;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    color: $gray-dark;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    font-size: em(14);
    letter-spacing: em(2.21, 14);
    line-height: (21.6 / 14);
    transition: 0.25s color ease-in-out;
  }
}