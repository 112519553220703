.testimonial-area {
  width: 100%;
  text-align: center;
  h1 {
    display: block;
  }
  .citation-image {
    width: em(95);
    height: em(95);
    border-radius: 50%;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    margin: 0 auto em(5);
  }
  figcaption {
    font-family: $font-family-serif;
    color: $gray-dark;
    font-size: em(36);
    font-weight: normal;
    line-height: (43 / 36);
    margin: 0 auto em(23, 36);
  }
}

.quote {
  &.under {
    blockquote {
      padding-top: em(35, 26);
    }
  }
  &.above {
    blockquote {
      padding-top: 3em;
    }
  }
  blockquote {
    font-size: em(26);
    line-height: (49.3 / 26);
    font-weight: 200;
    text-align: center;
    position: relative;
    margin-bottom: em(10, 26);
    span {
      position: relative;
      z-index: 2;
    }
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      width: em(81, 26);
      height: em(69, 26);
      background-image: url("//cdn2.hubspot.net/hub/218371/file-3437270510-svg/assets/images/blockquote-ticks.svg");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }
}

.testimonial-details {
  text-align: center;
  hr {
    margin-bottom: em(24);
  }
  a.cta-btn-transparent {
    margin-bottom: em(25, 14);
  }
  p {
    font-size: em(15);
    margin-top: em(15, 15);
  }
}

.testimonial-nest {
  padding-top: em(91);
  padding-bottom: em(100);
  @media (max-width: 991px) {
    .widget-type-space {
      display: none;
    }
    .span6, .span3 {
      width: 100% !important;
      max-width: 500px;
      margin: auto !important;
      float: none !important;
    }
  }
}

.mj-small-testimonial {
  .quote-region {
    position:relative;
    padding-top:1em;
    margin-top:em(38);
    &:before {
      content:'';
      display:inline-block;
      position:absolute;
      width:em(47.67);
      height:em(40);
      background-image:url('http://cdn2.hubspot.net/hub/218371/file-3437270510-svg/assets/images/blockquote-ticks.svg');
      background-position:center center;
      background-size:contain;
      left:0;
      top:0;
      z-index:1;
    }
  }
  blockquote {
    font-size:em(20);
    line-height:(32 / 20);
    font-weight:200;
    color:$gray-dark;
    border-left:0;
    padding:0;
    position:relative;
    z-index:2;
  }
  .citation {
    text-align:right;
    span, figure {
      display:inline-block;
      vertical-align:middle;
    }
    span {
      font-size:em(32);
      line-height:(35 / 32);
      font-family:$font-family-serif;
    }
    figure {
      width:em(35);
      height:em(35);
      background-size:cover;
      background-position:center top;
      border-radius:50%;
      margin-right:em(10);
    }
  }
}