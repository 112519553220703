/*
[hubspot-metadata]
{
   "path": "custom/system/css/mj_cos_styles.css",
   "category": "asset",
   "creatable": true
}
[end-hubspot-metadata]
*/

//------------------------------------
//UTILITIES
//------------------------------------

body {
  @media (max-width: 767px) {
    font-size: 12px !important;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px !important;
  }
  @media (min-width: 992px) and (max-width:1199px) {
    font-size: 15px !important;
  }
  @media (min-width:1200px) {
  	font-size: 16px !important;
  }
}

.left-column {
  @include media(screen-sm) {
    padding-right: 3%;
  }
}

.right-column {
  @include media(screen-sm) {
    padding-left: 3%;
  }
}

.vert-content {
  width: 100%;
  display: table;
  position: relative;
  z-index: 1;
}

.vert-content-inner {
  display: table-cell;
  padding: 0;
  vertical-align: middle;
}

.constrained-content {
  text-align: center;
  padding: em(28) em(20);
  &.white {
    background-color: white;
  }
  &.gray {
    background-color: $gray-lighter;
  }
  &.features {
    .actions {
      padding-top: em(25);
      padding-bottom: em(20);
    }
  }
  > .row-fluid-wrapper {
    width: 100%;
    max-width: 1420px;
    margin: auto;
    float: none;
    text-align: left;
  }
}

.super-constrained-content {
  text-align:center;
  padding:em(100) em(20);
  > .row-fluid-wrapper {
    max-width:680px;
    margin:auto;
    float:none;
    text-align:left;
    @media (max-width:767px) {
      width:100%;
    }
    @media (min-width:768px) {
      width:70%;
    }
  }
}

.row-fluid-wrapper > .row-fluid > .widget-type-space {
  min-height: 1px !important;
}

.widget-type-header {
  text-align: center;
  margin-bottom: em(40);
}

img {
  &.alignLeft {
    margin: 0 1.5em 1.5em 0;
  }
  &.alignRight {
    margin: 0 0 1.5em 1.5em;
  }
}

hr {
  width: 100%;
  margin: 0 auto em(24);
  max-width: em(72);
  border: 0;
  border-top: 3px solid rgba(#BBB2A6, 0.4);
}

//------------------------------------
//BUTTONS
//------------------------------------

.hs-button {
  border: none;
  margin: 1.5em 0 0 0;
  padding: 1em 1.25em;
  font-size: 1em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background: $link-color;
  display: inline-block;
  &:hover, &:focus, &:active {
    text-decoration: none;
    color: #fff;
    background: $link-hover-color;
  }
}

.cta-btn {
  display: inline-block;
  overflow: hidden;
  img {
    position: relative;
  }
}

//------------------------------------
//HEADINGS
//------------------------------------

//------------------------------------
//LISTS
//------------------------------------

ul.rss-items {
  list-style: none;
  margin: 0 0 1em;
  padding: 0;
  & > li.rss-item {
    line-height: normal !important;
    margin-bottom: .875em;
    br {
      display: none !important;
      position: absolute !important;
    }
  }
  a.rss-item {
    display: block;
    &:hover {
      color: $brand-primary;
    }
  }
}

//------------------------------------
//FORMS
//------------------------------------

.hs-form {
  padding-right: 0 !important;
}

.hs-form-field {
  width: auto !important;
  font-size: 1em;
  label {
    font-size: em(18);
    font-weight: 400;
    color: $gray-dark !important;
    margin: .75em 0 .25em;
  }
  input, textarea {
    color: $gray;
    padding: 12px 0 !important;
    text-indent: 10px;
    width: 100% !important;
    border: 1px solid #ccc;
    border-radius: 3px !important;
    height: auto !important;
    margin: 0;
    float: none !important;
    @include transition(0.3s);
    &:focus {
      box-shadow: none !important;
      border-color: $brand-primary;
      color: $brand-primary;
      outline: 1px solid $brand-primary;
    }
  }
  input[type="checkbox"],
  input[type="radio"] {
    width: auto !important;
    padding: 0 !important;
  }
  .checkbox,
  .follow-up-field {
    label {
      @include clearfix;
      text-transform: none;
    }
    input {
      width: auto !important;
      padding: 0 !important;
    }
  }
  .hs-field-desc {
    font-size: .875em;
    color: $gray !important;
    margin-bottom: 1em;
  }
  .email-validation {
    color: $brand-danger;
    font-size: .875em;
    margin: .5em 0 0;
  }
  textarea {
    min-height: 6em !important;
  }
}

.inputs-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hs-form fieldset {
  max-width: none !important;
  .hs-form-field {
    float: none !important;
  }
  .input {
    margin-right: 0 !important;
  }
}

.hs-form fieldset.form-columns-2 .hs-form-field {
  @include media(screen-sm) {
    width: 50% !important;
    &:first-child {
      float: left !important;
      .input {
        margin-right: 1em !important;
      }
    }
    &:last-child {
      float: right !important;
      .input {
        margin-left: 1em;
      }
    }
  }
}

.hs_clear {
  clear: both;
  display: block !important;
  visibility: hidden;
}

#captcha_wrapper {
  margin: 1em 0;
}

//------------------------------------
//STRUCTURE
//------------------------------------

.header-container-wrapper,
.body-container-wrapper,
.footer-container-wrapper,
.full-width-section {
  width: 100%;
  position: relative;
}

.header-container-wrapper,
.footer-container-wrapper {
  & > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

.body-container-wrapper {
  & > .container-fluid {
    //padding-right:20px; 
    //padding-left:20px;
    padding-left: 0;
    padding-right: 0;
  }
}

// Set Responsive Width
.body-container-wrapper,
.full-width-section > .row-fluid-wrapper,
.site-footer > .row-fluid-wrapper {
  //@include responsive-width;
}

/* The inner wrappers of your website */
.header-container,
.body-container,
.footer-container {
  overflow: visible;
}

.full-width-section {
  & > .row-fluid-wrapper {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
  }
}

//------------------------------------
//BODY CONTAINER
//------------------------------------

.body-container-wrapper {}

.body-container {}

.page-header {
  margin-top: 0 !important;
  border-bottom-color: $gray-light !important;
  h1 {
    margin-top: 0 !important;
  }
  p.secondary-header {
    margin-bottom: 1em !important;
  }
}