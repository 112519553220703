.side-by-side {
  .row {
    padding-bottom: em(50);
    margin: 0;
  }
  .text-image {
    @media (max-width: 767px) {
      display: table;
      .image, .text {
        float: none !important;
      }
      .image {
        display: table-header-group;
      }
      .text {
        display: table-footer-group;
      }
    }
    @media (min-width: 768px) {
      .image {
        padding-left: 7.5%;
        text-align: right;
      }
    }
  }
  .image-text {
    @media (min-width: 768px) {
      .image {
        padding-right: 7.5%;
        text-align: left;
      }
    }
  }
  .image img {
    width: 100%;
    height: auto;
  }
  .text {
    text-align: left;
    h3 {
      font-size: em(35);
      color: $pink-dark;
      font-weight: 300;
      line-height: (41 / 35);
      margin-bottom: em(21, 35);
      @media (max-width: 767px) {
        margin-top: em(15, 35);
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        margin-top: em(-5, 35);
      }
      @media (min-width: 1200px) {
        margin-top: em(28, 35);
      }
    }
    p {
      font-weight: 300;
    }
  }
}