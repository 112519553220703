body.menu-out {
  position: fixed;
  .mobile-menu-overlay {
    display: block;
  }
}

.mobile-menu-overlay {
  display: none;
  transition: 0.25s opacity ease-in-out;
  background-color: $pink-light;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  text-align: center;
  .hamburglar {
    position: fixed;
    top: 10px;
    right: 15px;
    filter: brightness(300%);
  }
  .inner {
    width: 85%;
    text-align: center;
    margin: em(20) auto;
    img {
      margin: auto;
    }
    > ul {
      overflow: scroll;
      height: 70vh;
      @media (min-width: 480px) {
        position: absolute;
        height: auto;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 20px 0 0 0;


      li {
        display: block;
        margin: 0;
        padding: 10px 0;
      }
      a {
        &, &:visited, &:focus {
          font-size: 16px;
          font-weight: 500;
          text-transform: uppercase;
          line-height: (18.2 / 16);
          letter-spacing: em(3.43, 16);
          text-shadow: 0 1px 0 #E87F6D;
          color: white;
          display: block;
          &:hover {
            text-decoration: none;
          }
        }
      }
      &.hs-menu-children-wrapper {
        padding-top: em(10);
        li {
          @media (max-width: 479px) {
            display: inline-block;
            margin-left: em(5);
            margin-right: em(5);
          }
        }
        a {
          font-size: 13px;
          line-height: 1em;
          text-transform: none;
          font-weight: 400;
          letter-spacing: 0;
        }
      }

    }
  }
  .bottom {
    position: absolute;
    width: 100%;
    bottom: 30px;
    left: 0;
    right: 0;
    text-align: center;
    ul {
      margin: auto;
      list-style-type: none;
      padding: 0;
      li {
        display: inline-block;
        padding: 0 12.5px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        svg {
          height: 24px;
          width: auto;
          g[fill="#777169"] {
            fill: white !important;
          }
        }
      }
    }
  }
}