.flexible-feature-box-region {
  @media (max-width: 991px) {
    margin-top: -5px;
  }
  @media (min-width: 992px) {
    margin-top: -10px;
  }
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  > span {
    > div {
      @extend .col-xs-12;
      @extend .col-sm-4;
      margin-left: 0 !important;
      padding: 0;
    }
  }
}

.mj-inspiration {
  .flexible-feature-box-region {
    @media (max-width: 1199px) {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
  .constrained-content > .row-fluid-wrapper {
    max-width: 1380px;
  }
  .sidebar-content {
    padding-top: 0;
    @media (max-width: 1199px) {
      width: 100% !important;
      margin-left: 0 !important;
      text-align: center;
      padding-top: 30px;
      > .row-fluid-wrapper {
        width: 100%;
        max-width: 500px;
        margin: auto;
        float: none !important;
      }
    }
    @media (max-width: 767px) {
      padding-top: 30px;
    }
    .cta-box {
      margin: 0;
    }
  }
  .main-content-nest {
    padding-top: em(55);
    padding-bottom: em(40);
  }
}