.large-gradient-billboard {
  position: relative;
  background-color: #fff;
  text-align: center;
  @media (max-width: 479px) {
    height: em(625);
  }
  @media (min-width: 480px) and (max-width: 767px) {
    height: 43em;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: em(625);
  }
  @media (min-width: 992px) {
    max-height: 100vh;
    min-height: em(625);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: em(730);
  }
  @media (min-width: 1200px) {
    height: em(900);
  }
  .down-arrow {
    width: em(52);
    height: em(52);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("//cdn2.hubspot.net/hub/218371/file-3434897540-svg/assets/images/down-arrow.svg");
    position: absolute;
    margin: auto;
    bottom: em(72);
    left: 0;
    right: 0;
    z-index: 3;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .image-underlay {
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-position: left center;
  }
  .gradient {
    position: absolute;
    height: 100%;
    width: 58%;
    top: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    z-index: 2;
    background-image: url("//cdn2.hubspot.net/hub/218371/file-3297385646-png/assets/images/billboard-gradient.png");
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      width: 58%;
    }
  }
  .constrain {
    width: 100%;
    max-width: 1380px;
    margin: auto;
  }
  .inner {
    z-index: 4;
    text-align: center;
    padding-left: em(20);
    padding-right: em(20);
    @media (max-width: 767px) {
      position: relative;
      padding-top: 6em;
    }
    @media (min-width: 768px) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    @media (min-width: 992px) {
      transform: translateY(-60%);
    }
    > img {
      width: 100%;
      height: auto;
      max-width: 558px;
      @media (min-width: 768px) and (max-width: 991px) {
        max-width: 300px;
      }
    }
    h4, .actions {
      max-width: 562px;
      margin: auto;
    }
    .actions {
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 11px;
      }
      a {
        position: relative;
        z-index: 6;
      }
    }
    h4 {
      font-size: em(24);
      line-height: (36.4 / 24);
      font-weight: 300;
      margin-top: em(18, 24);
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      width: 50%;
    }
  }
  hr {
    width: 100%;
    max-width: em(72);
    margin: em(27) auto em(35);
    border: 0;
    border-top: 3px solid #dad5ce;
  }
  .bottom-bar {
    background-color: $gold;
    color: white;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    padding: em(10);
    h5 {
      font-size: em(14);
      letter-spacing: em(1.75, 14);
      line-height: (21.6 / 14);
      font-weight: 500;
      text-transform: uppercase;
      color: white;
      text-align: center;
      margin: 0 auto;
    }
  }
}