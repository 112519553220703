//------------------------------------
//HEADER
//------------------------------------

.header-container-wrapper {
  z-index: 12;
}

.header-container {}

// Site Header
$site-header-height: 63px;
$main-nav-height: $site-header-height;

.logo-nest {
  position: relative;

  @media (max-width: 767px) {
    height: 40px;
  }

  @media (min-width: 768px) {
    height: 63px;
  }
}

body:not(.mj-home) {
  figure.logo > div {
    &.initial {
      opacity: 0 !important;
    }

    &.eventual {
      opacity: 1 !important;
    }
  }
}

figure.logo {
  width: em(280);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.25s height ease-in-out;

  @media (max-width: 767px) {
    height: 30px;
    width: 10.5em;
  }

  @media (min-width: 768px) {
    height: em(63);
  }

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0;
    transition: 0.25s opacity ease-in-out;

    &.initial {
      background-image: url('//cdn2.hubspot.net/hub/218371/file-3434615940-svg/assets/images/heart.svg');

      @media (max-width: 767px) {
        opacity: 0 !important;
      }
    }

    &.eventual {
      background-image: url('//cdn2.hubspot.net/hub/218371/file-3297307666-svg/assets/images/affix-logo.svg');

      @media (max-width: 767px) {
        opacity: 1 !important;
      }
    }
  }
}

.site-header {
  width: 100%;
  text-align: center;
  background-color: rgba(#fff, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.25s height ease-in-out;

  @media (max-width: 767px) {
    height: 40px;
  }

  @media (min-width: 768px) {
    height: $site-header-height;
  }

  &.affix {
    @media (min-width: 768px) {
      height: 83px;
      position: fixed;

      .logo-nest {
        height: 83px;
      }

      .hs-menu-wrapper.hs-menu-flow-horizontal > ul > li > a {
        line-height: 83px;
      }
    }
  }

  &:not(.affix) .logo {
    @media (min-width: 768px) {
      height: em(40);
    }
  }

  &:not(.affix) .logo .initial {
    opacity: 1;
  }

  &.affix .logo .eventual {
    opacity: 1;
  }

  .row-fluid-wrapper {
    width: 100%;
    max-width: 1380px;
    margin: auto;
    float: none;
  }

  .main-nav {
    width: 100%;
    height: $main-nav-height;
  }

  /* Menu */
  .hs-menu-wrapper {
    a {
      color: $brand-primary;
      font-size: em(14);
      font-weight: 500;
      letter-spacing: em(3, 14);
      padding: 0 em(20, 14);
      color: $gray;
      text-decoration: none;
      text-transform: uppercase;
      display: block;
      transition: 0.25s all ease-in-out;

      &:hover {
        color: $pink-dark;
      }
    }

    // Menu
    &.hs-menu-flow-horizontal {
      // List reset
      ul {
        list-style: none;
        display: block;
        margin: 0;
        padding: 0;
      }

      // Top level list
      & > ul {
        text-align: right;

        & > li {
          display: inline-block;
          padding: 0;
          margin: 0;
          float: none !important;

          &:nth-child(3) a {
            padding-right: em(34.5, 14);
          }

          &:nth-child(n+4) a {
            padding: 0 em(8.5, 14);
          }

          // Top level links
          & > a {
            padding: 0 1em;

            @media (max-width: 767px) {
              line-height: 40px;
            }

            @media (min-width: 768px) {
              line-height: $site-header-height;
            }

            img, svg {
              vertical-align: middle;
            }

            g[fill='#777169'] {
              transition: 0.25s fill ease-in-out;
            }

            &:hover {
              svg g[fill='#777169'] {
                fill: $pink-dark;
              }
            }
          }

          &:last-child {
            & > a {
              border-right: none;
            }
          }
        }
      }

      // List item with drop down
      li.hs-item-has-children {}

      // Drop down list
      ul.hs-menu-children-wrapper {
        background-color: $gray-lighter;

        li {
          background: $gray-lighter;

          a {
            border-bottom: 1px solid $gray-light;
            color: $brand-primary;
            font-size: .875em;
          }

          &:hover > a,
          a:hover {
            background-color: $gray-light;
          }
        }
      }

      .active-branch > a {
        color: $brand-primary;
      }
    }
  }

  .widget-type-menu {
    display: none;
    visibility: hidden;
  }

  @include media(screen-md) {
    .widget-type-menu {
      display: block;
      visibility: visible;
    }
  }


  @media (min-width: 992px) and (max-width: 1199px) {
    .widget-type-menu {
      a {
        font-size: .8em !important;
        padding-left: .5em !important;
        padding-right: .5em !important;

        &[href*='twitter'],
        &[href*='facebook'],
        &[href*='pinterest'],
        &[href*='instagram'] {
          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}

//------------------------------------
//MOBILE MENU TRIGGER
//------------------------------------

.hamburglar {
  border: 0;
  background: transparent;
  width: 24px;
  height: 20px;
  padding: 0;
  margin: 0;
  top: 22px;

  &:focus {
    outline: 0;
  }

  @media (max-width: 991px) {
    position: absolute;
    right: 15px;
    bottom: 10px;
    z-index: 2001;
  }

  @media (max-width: 768px) {
    top: 10px;
  }

  @media (min-width: 992px) {
    display: none;
  }

  img {
    width: 24px;
    height: 20px;
    vertical-align: top;
  }
}

//------------------------------------
//MOBILE MENU
//------------------------------------

.sidr {
  display: none;
  position: absolute;
  position: fixed;
  top: 0;
  z-index: 999999;
  overflow-x: none;
  overflow-y: auto;
  background: $brand-primary;
  border-left: 1px solid transparent;
  font-size: $font-size-base;

  @include size(260px, 100%);

  &.right {
    left: auto;
    right: -260px;
  }

  &.left {
    left: -260px;
    right: auto;
  }

  .sidr-inner {
    padding: 20px 0;
  }

  h1, h2, h3, h4, h5, h6, p, div {
    padding: 0 20px;
  }

  a {
    text-decoration: none;
  }

  ul {
    border-top-color: transparent;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      border-bottom-color: transparent;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }

      a, span {
        font-size: $font-size-h3;
        color: #fff;
        text-decoration: none;
        display: block;
        font-weight: normal;
        cursor: pointer;
        line-height: 1.2;
        padding: 5px 22px;

        &:hover {
          @include opacity(0.8);
        }
      }

      &.active > a,
      &.active > span,
      &.sidr-class-active > a,
      &.sidr-class-active > span {
        color: $brand-primary !important;
        background: #fff !important;
      }

      & > ul {
        padding-bottom: 10px;
      }

      & > ul li {
        margin-top: 0;
      }

      & > ul li a,
      & > ul li span {
        font-size: 14px;
        padding: 10px 22px;
        color: #fff;
        text-transform: none;
        line-height: normal;

        @include opacity(0.7);
      }
    }
  }
}
